<template>
  <div>
    <b-card>
      <advanced-table
        :tableColumns="tableColumns"
        dataAction="users/GET_USERS"
        :createRoute="{ name: 'user-details' }"
        ref="usersTable"
      >
        <template #cell(devices)="data">
          <b-button
            variant="light"
            :to="{ name: 'user-details', params: { id: data.item.id } }"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle text-body"
              :data-id="data.item.id"
            />
          </b-button>
        </template>
        <template #cell(login_as)="data">
          <b-button variant="light" @click="loginAs(data.item.id)">
            <feather-icon
              icon="UserIcon"
              size="16"
              class="align-middle text-body"
              :data-id="data.item.id"
            />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'user-details', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('edit') }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteUser(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </advanced-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    AdvancedTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BFormCheckbox,
  },
  data() {
    return {
      tableColumns: [
        { key: 'firstName', label: i18n.tc('firstname'), sortable: true },
        { key: 'lastName', label: i18n.tc('lastname'), sortable: true },
        { key: 'email', label: 'Email ali uporabniško ime', sortable: true },
        { key: 'devices', label: i18n.tc('devices') },
        { key: 'login_as', label: i18n.tc('login_as') },
        { key: 'actions', label: i18n.tc('actions') },
      ],
    };
  },
  methods: {
    async loginAs(userId) {
      const response = await this.$store.dispatch('auth/LOGIN_AS', {
        userId,
      });
      if (response) {
        const userData = response.data;
        useJwt.setToken(response.data.access_token);

        this.$store.commit('auth/SET_USER_DATA', userData);
        this.$ability.update(userData.ability);
        this.$i18n.locale = userData.lang;

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title:
              this.$t('login.logged_in_as') +
              ' ' +
              userData.firstname +
              ' ' +
              userData.lastname,
            icon: 'UserCheckIcon',
            variant: 'success',
            text: this.$t('login.user_login_success'),
          },
        });
        this.$router.push({ name: 'locations' });
      }
    },
    openModal(userId, email) {
      this.devicePermissionUser = { userId, email };
      this.$router.push({ name: '' });
      this.$bvModal.show('modal-edit');
    },
    deleteUser(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('confirm_user_deletation_description'), {
          title: this.$t('confirm_deletation'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('yes'),
          cancelTitle: this.$t('no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const res = await this.$store.dispatch('users/DELETE_USER', id);
            if (res) {
              this.$refs.usersTable.refresh();
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('deleted'),
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: this.$t('user_deleted'),
                },
              });
            }
          }
        });
    },
  },
};
</script>

<style></style>
